var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"snapshot-card",class:{
    'cursor-pointer': _vm.clickable,
    'snapshot-card--absolute': _vm.absolute,
    'snapshot-card--highlighted': _vm.highlighted,
    'snapshot-card--chip': _vm.chip,
    'snapshot-card--no-label': !_vm.withLabel,
  },style:(_vm.tooltipWidthStyle),attrs:{"title":_vm.item.label},on:{"click":_vm.onClick}},[_c('div',{staticClass:"position-relative"},[(_vm.showSnapshot)?_c('img',{class:'zoomable-img__img-background',attrs:{"src":_vm.thumbnailUrl ?? _vm.item.thumbnailUrl}}):_vm._e(),_vm._v(" "),(_vm.showSnapshot)?_c('v-img',{key:`${_vm.item.thumbnailUrl}`,staticClass:"snapshot-card__img elevation-4",attrs:{"src":_vm.thumbnailUrl ?? _vm.item.thumbnailUrl,"lazy-src":_vm.getResizedUrl(_vm.item.placeholderThumbnailUrl),"eager":"","contain":"","width":_vm.width,"max-width":_vm.width,"height":_vm.width / _vm.aspectRatio},on:{"error":_vm.onError,"load":_vm.onLoad},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"h-100 w-100 d-flex justify-center align-center e-bg-gray-800 opacity-30"},[(_vm.isError)?_c('v-icon',{attrs:{"size":"3xl","color":"white"}},[_vm._v("fa-image")]):(_vm.isLoading)?_c('EvercamLoadingAnimation',{attrs:{"size":"ThreeXl"}}):_vm._e()],1)]},proxy:true}],null,false,2529213372)},[(_vm.item.title && _vm.withLabel)?_c('div',{staticClass:"snapshot-card__img-overlay"},[_vm._v("\n        "+_vm._s(_vm.item.title)+"\n      ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.withLabel)?_c('div',{staticClass:"snapshot-card__label w-100 d-flex align-center font-weight-medium py-1 pl-3",class:_vm.labelClass,style:(_vm.tooltipWidthStyle)},[_vm._t("label",function(){return [_vm._v("\n        "+_vm._s(_vm.item.label)+"\n      ")]})],2):_vm._e()],1),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }