<template>
  <SnapshotCard
    :item="snapshotTooltipItem"
    :width="width"
    :absolute="absolute"
    :highlighted="highlighted"
    :clickable="clickable"
    :resize="resize"
    :label-class="labelClass"
    :aspect-ratio="aspectRatio"
    :show-snapshot="showThumbnail"
    :chip="chip"
    :with-label="withLabel"
    class="camera-card cursor-pointer ma-3"
    @click="$emit('click', $event)"
  >
    <template #label>
      <CameraStatusIcon
        :camera="camera"
        class="mr-2"
        data-test-id="selected-camera-status"
      />
      <span
        class="camera-card__label title-text ml-1 caption camera-name"
        :class="labelClass"
        data-test-id="selected-camera-title"
      >
        {{ camera.name }}
        <template v-if="showExid"> ({{ camera.exid }}) </template>
      </span>
      <v-icon
        v-if="icon"
        class="e-ml-auto e-mr-2"
        small
        data-test-id="dropdown-icon"
        >{{ icon }}</v-icon
      >
    </template>
    <slot></slot>
  </SnapshotCard>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import type { Camera } from "@evercam/shared/types"
import CameraStatusIcon from "@evercam/shared/components/CameraStatusIcon"
import SnapshotCard from "@evercam/shared/components/SnapshotCard"

export default Vue.extend({
  name: "CameraCard",
  components: { SnapshotCard, CameraStatusIcon },
  props: {
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
    targetTimestamp: {
      type: [String],
      default: undefined,
    },
    token: {
      type: String,
      required: true,
    },
    absolute: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 100,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    resize: {
      type: Boolean,
      default: true,
    },
    labelClass: {
      type: String,
      default: "",
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9,
    },
    showThumbnail: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "",
    },
    chip: {
      type: Boolean,
      default: false,
    },
    showExid: {
      type: Boolean,
      default: false,
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      previousTimestamp: "",
    }
  },
  computed: {
    snapshotTooltipItem() {
      return {
        label: this.camera.name,
        thumbnailUrl: this.getThumbnailUrl(this.targetTimestamp),
        placeholderThumbnailUrl: this.getThumbnailUrl(this.previousTimestamp),
        fallbackThumbnailUrl: this.getThumbnailUrl(),
      }
    },
  },
  watch: {
    targetTimestamp(_, previousTimestamp) {
      this.previousTimestamp = previousTimestamp
    },
  },
  methods: {
    getThumbnailUrl(timestamp?: string): string {
      const baseUrl = this.$config.public.apiURL
      const queryParams = `authorization=${this.token}&view=true`

      if (!timestamp) {
        return `${baseUrl}/cameras/${this.camera.exid}/thumbnail?${queryParams}`
      }

      const t = new Date(
        Math.max(
          new Date(this.camera.createdAt).getTime(),
          new Date(timestamp).getTime()
        )
      ).toISOString()

      return `${baseUrl}/cameras/${this.camera.exid}/recordings/snapshots/${t}/nearest?${queryParams}`
    },
  },
})
</script>

<style lang="scss">
.camera-card {
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out;
  border-radius: 0.5em;
  overflow: hidden;
  &:not(.snapshot-card--highlighted) {
    box-shadow: 1px 2px 10px -2px #000;
  }
  &.camera-card--hoverable:hover:not(.list-transition-move):not(
      .snapshot-card--highlighted
    ) {
    box-shadow: 0 8px 12px -5px rgba(0, 0, 0, 0.85);
    transform: scale(1.025);
  }

  &__label {
    font-size: small;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
