import { GrafanaMetricId, KitMetricId } from "@evercam/shared/types"

export const KitMetricNames = {
  [GrafanaMetricId.BatteryVoltageAndCurrentStats]:
    "Battery Voltage and Current stats",
  [GrafanaMetricId.SbcCpuLoad]: "SBC CPU Load",
  [GrafanaMetricId.SbcSystemLoad5MnAvg]: "SBC System Load 5 Mn Avg",
  [GrafanaMetricId.SbcSystemLoad15MnAvg]: "SBC System Load 15 Mn Avg",
  [GrafanaMetricId.SbcRamUsed]: "SBC RAM Used",
  [GrafanaMetricId.SbcSwapUsed]: "SBC Swap Used",
  [GrafanaMetricId.SbcRootFsUsed]: "SBC Root FS Used",
  [GrafanaMetricId.SbcCpuCoresTotal]: "SBC CPU Cores Total",
  [GrafanaMetricId.SbcRamTotal]: "SBC RAM Total",
  [GrafanaMetricId.SbcSwapTotal]: "SBC Swap Total",
  [GrafanaMetricId.SbcRootFsTotal]: "SBC Root Fs Total",
  [GrafanaMetricId.SbcUptime]: "SBC Uptime",
  [GrafanaMetricId.SbcCpuStats]: "SBC CPU Stats",
  [GrafanaMetricId.SbcRamStats]: "SBC RAM Stats",
  [GrafanaMetricId.SbcNetworkStats]: "SBC Network Stats",
  [GrafanaMetricId.SbcTimeSyncStats]: "SBC Time Sync Stats",
  [GrafanaMetricId.SbcTemperatureStats]: "SBC Temperature Stats",
  [GrafanaMetricId.SbcTimeSyncDriftStats]: "SBC Time Sync Drift Stats",
  [GrafanaMetricId.CameraInfo]: "Camera Info",
  [GrafanaMetricId.CameraRecordingStateTimeline]:
    "Camera Recording State Timeline",
  [GrafanaMetricId.CameraStreamInfo]: "Camera Stream Info",
  [GrafanaMetricId.LocalStorage]: "Local Storage",
  [GrafanaMetricId.StorageSpaceUsed]: "Storage Space Used",
  [GrafanaMetricId.RouterTemperature]: "Router Temperature",
  [GrafanaMetricId.RouterCpuLoad]: "Router CPU Load",
  [GrafanaMetricId.RouterRamUsed]: "Router RAM Used",
  [GrafanaMetricId.RouterSystemLoad5MnAvg]: "Router System Load 5 Mn Avg",
  [GrafanaMetricId.RouterSwapTotal]: "Router Swap Total",
  [GrafanaMetricId.RouterRamTotal]: "Router RAM Total",
  [GrafanaMetricId.RouterUptime]: "Router Uptime",
  [GrafanaMetricId.RouterCpuCoresTotal]: "Router CPU Cores Total",
  [GrafanaMetricId.RouterSignalReliability]: "Router Signal Reliability",
  [GrafanaMetricId.RouterSINR]: "Router SINR",
  [GrafanaMetricId.RouterRSRP]: "Router RSRP",
  [GrafanaMetricId.RouterRSRQ]: "Router RSRQ",
  [GrafanaMetricId.RouterRSSI]: "Router RSSI",
  [GrafanaMetricId.RouterSignalStrengthStats]: "Router Signal Strength Stats",
  [GrafanaMetricId.RouterSignalQualityStats]: "Router Signal Quality Stats",
  [GrafanaMetricId.RouterDataConsumptionStats]: "Router Data Consumption Stats",
  [GrafanaMetricId.PanelVoltageAndPowerStats]: "Panel Voltage And Power Stats",
  [GrafanaMetricId.ExNvrStatus]: "Ex Nvr Status",
  [GrafanaMetricId.ExNvrVersion]: "Ex Nvr Version",
  [GrafanaMetricId.ExNvrMemoryUsage]: "Ex Nvr Memory Usage",
  [GrafanaMetricId.ExNvrLogs]: "Ex Nvr Logs",
  [KitMetricId.BatteryStats]: "Battery Voltage & Current",
  [KitMetricId.PanelPowerStats]: "Panel Voltage & Power",
}
