<template>
  <div
    :title="item.label"
    :style="tooltipWidthStyle"
    class="snapshot-card"
    :class="{
      'cursor-pointer': clickable,
      'snapshot-card--absolute': absolute,
      'snapshot-card--highlighted': highlighted,
      'snapshot-card--chip': chip,
      'snapshot-card--no-label': !withLabel,
    }"
    @click="onClick"
  >
    <div class="position-relative">
      <img
        v-if="showSnapshot"
        :src="thumbnailUrl ?? item.thumbnailUrl"
        :class="'zoomable-img__img-background'"
      />
      <v-img
        v-if="showSnapshot"
        :key="`${item.thumbnailUrl}`"
        :src="thumbnailUrl ?? item.thumbnailUrl"
        :lazy-src="getResizedUrl(item.placeholderThumbnailUrl)"
        eager
        contain
        :width="width"
        :max-width="width"
        :height="width / aspectRatio"
        class="snapshot-card__img elevation-4"
        @error="onError"
        @load="onLoad"
      >
        <div v-if="item.title && withLabel" class="snapshot-card__img-overlay">
          {{ item.title }}
        </div>
        <template #placeholder>
          <div
            class="h-100 w-100 d-flex justify-center align-center e-bg-gray-800 opacity-30"
          >
            <v-icon v-if="isError" size="3xl" color="white">fa-image</v-icon>
            <EvercamLoadingAnimation v-else-if="isLoading" size="ThreeXl" />
          </div>
        </template>
      </v-img>
      <div
        v-if="withLabel"
        class="snapshot-card__label w-100 d-flex align-center font-weight-medium py-1 pl-3"
        :class="labelClass"
        :style="tooltipWidthStyle"
      >
        <slot name="label">
          {{ item.label }}
        </slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { TimelinePlayerTooltipItem } from "@/types/index.ts"

export default Vue.extend({
  name: "SnapshotCard",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    item: {
      type: Object as PropType<TimelinePlayerTooltipItem>,
      required: true,
    },
    absolute: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 120,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    resize: {
      type: Boolean,
      default: true,
    },
    labelClass: {
      type: String,
      default: "",
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9,
    },
    showSnapshot: {
      type: Boolean,
      default: true,
    },
    chip: {
      type: Boolean,
      default: false,
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isError: false,
      isLoading: true,
      retries: 0,
      thumbnailUrl: "",
    }
  },
  computed: {
    tooltipWidthStyle(): Record<string, any> {
      if (this.chip) {
        return {}
      }

      return {
        width: `${this.width}px`,
      }
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(current, previous) {
        if (current.thumbnailUrl === previous?.thumbnailUrl) {
          return
        }
        this.thumbnailUrl = this.getResizedUrl(current.thumbnailUrl)
        this.isLoading = true
        this.isError = false
      },
    },
  },
  methods: {
    onClick() {
      this.$emit("click", this.item)
    },
    getResizedUrl(url: string): string {
      if (!url) {
        return ""
      }

      if (!this.resize) {
        return url
      }

      return this.$imgproxy.get360pResizedImageUrl(url)
    },
    onError() {
      console.log("Error loading image", this.item.thumbnailUrl, this.retries)
      this.isError = true
      this.thumbnailUrl = this.item.fallbackThumbnailUrl
    },
    onLoad() {
      this.$nextTick(() => {
        this.isError = false
        this.isLoading = false
      })
    },
  },
})
</script>

<style lang="scss">
.snapshot-card {
  box-shadow: 1px 2px 10px -2px #000;
  border-radius: 0.5em;
  overflow: hidden;
  &--absolute {
    position: absolute;
    top: 8px;
    left: 0;
    transform: translate(-50%, -100%);
    z-index: 4;
  }
  &--highlighted {
    box-shadow: 0 0 0 4px var(--v-primary-base);
  }
  &__label {
    font-size: small;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: var(--v-background-base);
    color: var(--v-on_background-base);
  }
  &__img-overlay {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(17, 24, 39, 0.75);
    color: white;
    font-size: small;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.1em 0.85em;
    display: inline-block;
    border-bottom-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
  }
  &--chip {
    display: flex;
    &:not(.snapshot-card--highlighted) {
      box-shadow: none !important;
    }
    &.snapshot-card--highlighted {
      box-shadow: 0 0 0 3px var(--v-primary-base);
    }
    background-color: var(--v-surface-base);
    border-radius: 0.35em !important;
    .snapshot-card__label {
      padding-right: 1em;
      background-color: var(--v-surface-base);
    }
  }
  .v-image__image--preload {
    filter: none;
  }
  .zoomable-img__img-background {
    filter: blur(10px);
    position: absolute;
    z-index: -1;
    width: 100% !important;
    height: 100% !important;
    transform: none;
  }
}
</style>
